/** Global
============================================ */

/**/
* {
   @include transition(all 0.3s ease-in-out);
}
/**/
body {
    font-family: $font-stack;
    font-size: 12px;
    font-weight: 300;
    min-width: 320px;
    &.scroll-disabled {
        overflow: hidden;
        height: 100vh;
    }    
}
.clear {
	clear:both;
}
.no-padding {
    padding: 0;
}
.line-break {
    border-bottom: 1px solid $blue-cerulean;
    margin-bottom: 3em;
}
.container-fluid {
    padding-top: 9em;
    padding-bottom: 9em;
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}
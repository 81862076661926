/** Mixins
============================================ */

@mixin transition($transition){
    -webkit-transition: $transition;
       -moz-transition: $transition;
        -ms-transition: $transition;
         -o-transition: $transition;
            transition: $transition;
}
@mixin transform($transform){
    -webkit-transform: $transform;
       -moz-transform: $transform;
        -ms-transform: $transform;
         -o-transform: $transform;
            transform: $transform;
}
@mixin border-radius($tl,$tr,$br,$bl){
    
        -webkit-border-top-left-radius: $tl;
       -webkit-border-top-right-radius: $tr;
    -webkit-border-bottom-right-radius: $br;
     -webkit-border-bottom-left-radius: $bl;

            -moz-border-radius-topleft: $tl;
           -moz-border-radius-topright: $tr;
        -moz-border-radius-bottomright: $br;
         -moz-border-radius-bottomleft: $bl;

                border-top-left-radius: $tl;
               border-top-right-radius: $tr;
            border-bottom-right-radius: $br;
             border-bottom-left-radius: $bl;    
    
    /* Prevent background color leak outs */
               -webkit-background-clip: padding-box; 
                  -moz-background-clip: padding; 
                       background-clip: padding-box;
                              overflow: hidden;
    
}
@mixin shadow($value) {
    -webkit-box-shadow: $value;
       -moz-box-shadow: $value;
            box-shadow: $value;
}
@mixin clearfix(){
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}
/** Header
============================================ */

header {
    padding: 3em;
    text-align: center;
    .logo {
        width: auto;
        height: 5em;
    }    
}
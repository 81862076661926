/** Footer
============================================ */

footer {
    text-align: center;
    &.container-fluid {
        padding-top: 0;
        padding-bottom: 0;
    }
    .no-padding:first-child {
        background-color: $black-mine-shaft;
        padding: 3em 0;
        ul {
            margin: 0 auto;
            width: auto;
            li {
                display: inline;
                a {
                    color: $grey-mystic;
                    font-size: 1.5em;
                    font-weight: 300;
                    padding-left:.5em;
                    padding-right: 1em;
                    text-decoration: none;
                    &:hover {
                        color: $blue-cerulean;
                        i {
                            color: $blue-cerulean;
                        }
                    }
                    i {
                        margin-right: 12px;
                    }
                }
            }
        }
    }
    .no-padding:last-child {
        background-color: #000000;
        color: $grey-mystic;
        padding: 2em 0;
        a {
            color: $blue-cerulean;
        }
    }
    .footer-links a {
        cursor: pointer;
    }
}
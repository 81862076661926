.slider {
    position: relative;
    .slider-info {
        position: absolute;
        z-index: 51;
        transform: translateY(-50%);
        top: 50%;        
        h1 {
            color: #ffffff; 
            padding: 0px 72px 48px 72px;
            width: 17ch;
            position: relative;
            z-index: 53;
        }
        .btn-wrap {
            position: relative;
            z-index: 54;
            padding-left: 72px;
            text-align: left;
            .btn {
                &:first-child {
                    margin-right: 1em;
                }
                &.btn-primary,
                &.btn-secondary {
                    &:hover {
                        background-color: $grey-mystic;
                    }
                }
            }
        }    
    }
    .bx-wrapper {
        border: none;
        margin: 0 auto;
        .bx-viewport {
            height: 76vh !important;
            min-height: 530px;
            .bxslider {
                li {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    min-height: 600px;
                    height: 76vh !important;
                    width: 100%;
                    transition: none;
                    &:before {
                        content: '';
                        width: 302px;
                        height: 767px;
                        background-image: url(/img/cat-paw-prints.png);
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        z-index: 52;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        z-index: 51;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 60%;
                        height: 76vh;
                        min-height: 600px;
                        background: -moz-linear-gradient(left, rgba(0,60,81,1) 0%, rgba(0,60,81,0) 100%);
                        background: -webkit-linear-gradient(left, rgba(0,60,81,1) 0%,rgba(0,60,81,0) 100%);
                        background: linear-gradient(to right, rgba(0,60,81,1) 0%,rgba(0,60,81,0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003c51', endColorstr='#00003c51',GradientType=1 );
                    }
                }
            }
        }
        .bx-controls {
            position: relative;
            background-color: $blue-cerulean;
            width: 100%;
            padding: 0 0 1em 0;
            border-top: 6px solid $grey-mystic;
            .bx-pager {
                padding-top: 0;
                position: static;
                .bx-pager-link {
                    background-color: $grey-mystic;
                    &.active {
                        background-color: $blue-prussian;
                    }
                }
            }
        }
    }
}
/** Main Content
============================================ */

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 60;
    display: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 72px;
    padding-bottom: 72px;
    &.active {
        .close-times {
            font-size: 3em;
            color: $blue-cerulean;
            position: absolute;
            top: 24px;
            right:36px;
            cursor: pointer;
        }
        .container {
            max-width: 906px;
            margin: 0 auto;
            .text-wrap {
                h2, h3 {
                    color: $blue-cerulean;
                    margin-top: 1em;
                }
                h2 {
                    border-bottom: 1px solid $blue-cerulean;
                    padding-bottom: .5em;
                    margin-bottom: 1em;
                }
                .btn-wrap {
                    width: 100%;
                    text-align: center;
                    margin-top: 36px;
                }
            }
        }
    }
}
.container-intro {
    background-color: $grey-mystic;
    background-image: url(/img/cat-paw-prints.png);
    background-position: top -10px right -10px;
    background-size: 20%;
    background-repeat: no-repeat;    
    .container {
        max-width: 906px;
        .text-wrap {
            padding-left: 6em;
            padding-right: 6em;
            h2 {
                text-align: center;
            }
            .btn-wrap {
                margin-top: 3em;
            }
        }
    }
}
.container-the-device {
    position: relative;
    .text-wrap {
        padding-left: 6em;
        padding-right: 6em;
        .btn-wrap {
            margin-top: 3em;
            text-align: left;
        }        
    }
    .img-wrap {
        padding-top: 4em;
        overflow: hidden;
        img {
            width: 100%;
            transform: translate3d(100%, 0, 0);
        }
    }
}
.container-video {
    background-color: $blue-cerulean;
    text-align: center;
    background: rgb(0,176,225);
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,176,225,1) 40%, rgba(0,123,158,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,176,225,1) 40%,rgba(0,123,158,1) 100%);
    background: radial-gradient(ellipse at center, rgba(0,176,225,1) 40%,rgba(0,123,158,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b0e1', endColorstr='#007b9e',GradientType=1 );
    .container {
        max-width: 906px;
        h2 {
            color: #ffffff;
        }
        .text-wrap {
            padding-left: 6em;
            padding-right: 6em;
            p {
                font-size: 1.2em;
            }
            .video {
                max-width: 906px;
                margin: 0 auto;
                .video-wrap {
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    padding-top: 25px;
                    height: 0;
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 2px solid $blue-cerulean;
                        border-radius: 12px;
                        overflow: hidden;
                    }
                }
            }
            .expand-link {
                font-size: 1.2em;
                cursor: pointer;
                margin-bottom: 36px;
                display: inline-block;
                position: relative;
                text-decoration: none;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    top: 10px;
                    background-color: #ffffff;
                }
                &:before {
                    right: calc(100% + 15px); 
                }
                &:after {
                    left: calc(100% + 15px);
                }
                .fa {
                    margin-left: 5px;
                }
                &.active {
                    .fa {
                        transform: rotate(180deg);
                    }
                }
            }
            .video-notes {
                max-height: 0;
                overflow-y: hidden;
                text-align: left;
                &.active {
                    overflow: auto;
                    max-height:1000px;
                    transition-duration: 500ms;
                    margin-bottom: 24px;
                }
            }
            .shadow {
                max-width: 764px;
                width: 100%;
                height: 48px;
                margin: 0 auto;
                margin-top: 36px;
                background-image: url(/img/video-shadow.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}
.container-simple {
    background-image: url(/img/cat-roaming-left.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 0;
    .cat-roaming-full {
        width: 100%;
        display: none;
    }
    .text-wrap {
        background-color: rgba(0,60,82,.8);
        padding: calc(6em + 15px);
    }
}
.container-buy-now {
    background-color: $grey-mystic;
    position: relative;
    h2 {
        text-align: center;
    }
    .text-wrap {
        padding-left: 6em;
        padding-right: 6em;
        h3 {
            padding-bottom: .5em;
        }
        .price {
            font-size: 3em;
            font-weight: 300;
            color: #ffffff;
            background-color: #26be1f;
            display: inline;
            padding: .2em .5em;
            border-radius: 6px;
            sup {
                font-size: .3em;
                top: -1.6em;
            }
        }
        .line-break {
            margin-top: 3em;
        }
        .bullets {
            margin-bottom: 1em;
        }
        .btn {
            box-sizing: border-box;
        }
        label {
            width: 100%;
            font-size: 1.2em;
            margin-bottom: 12px;
        }
        input[type=text] {
            width: 100%;
            border: 1px solid $grey-silver;
            border-radius: 4px;
            height: 48px;
            padding: 0 1em;
            font-size: 1.2em;
            margin-bottom: 24px;
        }
    }
    .img-wrap {
        padding-top: 8em;
        padding-left: 6em;
        padding-right: 6em;
        overflow: hidden;
        img {
            width: 100%;
            transform: translate3d(100%, 0, 0);
        }
    }
}
.container-donate {
    background-color: $blue-cerulean;
    padding-top: 0;
    padding-bottom: 0;
    .text-wrap {
        padding: 6em;
        .btn-wrap {
            margin-top: 3em;
            text-align: left;          
        }
    }
    .img {
        background-image: url(/img/cat-shelter.jpg);
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 300px;
    }
}

// Thank you page
.thankyou {
    text-align: center;
    .logo {
        width: auto;
        height: 4em;
    }
    h2 {
        padding-bottom: .5em;
    }
    p {
        padding-bottom: 36px;
    }
}
/** Buttons
============================================ */

.btn-wrap {
    width: 100%;
    text-align: center;
}
.btn {
    min-width: 240px;
    min-height: 72px;
    font-weight: 300;
    padding: 22px 1em;
    border: 1px solid $grey-mystic;
    transition: all .5s ease-in-out;
    border-radius: 12px;
    font-family: $font-stack;
    font-size: 1.5em;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    &.btn-primary {
        color: $grey-mystic;
        background-color: $blue-cerulean;
        text-decoration: none;
        &:hover {
            background-color: transparent;
            color: $blue-cerulean;
            border-color: $blue-cerulean;
        }
        &.reversed {
            background-color: #ffffff;
            color: $blue-cerulean;
            &:hover {
                color: $grey-mystic;
                background-color: $blue-prussian;
            }            
        }
    }
    &.btn-secondary {
        color: $grey-mystic;
        background-color: $blue-prussian;
        text-decoration: none;
        &:hover {
            background-color: transparent;
            color: $blue-prussian;
            border:1px solid $blue-prussian;
        }        
    }
    &.btn-small {
        font-size: 1.2em;
        min-width: 160px;
        min-height: 48px;
        padding: 12px;
    }
}
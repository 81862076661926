/** Typography
============================================ */

h1 {
    font-size: 6em;
    line-height: 1.2em;
    font-weight: 300;
    color: $blue-cerulean;
    padding-bottom: 1em;
    strong {
        font-weight: 400;
    }
}
h2 {
    font-size: 4em;
    line-height: 1.2em;
    font-weight: 300;
    color: $blue-cerulean;
    padding-bottom: 1em;
    strong {
        font-weight: 400;
    }    
}
h3 {
    font-size: 3em;
    line-height: 1.2em;
    padding-bottom: .8em;
    font-weight: 400;
    color: $blue-prussian;
}
p {
    font-size: 1.5em;
    line-height: 1.4em;
    padding-bottom: 1em;
    &.small {
        font-size: 1.16em;
    }
    strong {
        font-weight: 400;
    }
}
ul.bullets {
    margin-left: 1.5em;
    position: relative;
    li {
        font-size: 1.5em;
        line-height: 1.4em;
        padding-bottom: 1em;
        margin-left: 1.5em;
        &:before {
            content: '\f1b0';
            font-family: 'FontAwesome';
            position: absolute;
            right: 100%;
            color: $blue-cerulean;
        }
        &.highlight {
            font-weight: 400;
        }
    }
}
.white-text {
    h1, h2, h3, p, li, a {
        color: $grey-mystic;
    }
}
/** Responsive CSS
============================================ */

@media (max-width:991px){
    .container-intro {
        h2 {
            br {
                display: none;
            }
        }
    }
    .container-the-device .img-wrap {
        padding-top: 0;
    }

}
@media (max-width:767px){
    .container-fluid {
        padding-top: 36px;
        padding-bottom: 36px;
        &.container-intro .text-wrap,
        &.container-the-device .text-wrap,
        &.container-video .text-wrap,
        &.container-buy-now .text-wrap,
        &.container-donate .text-wrap {
            padding-left: 36px;
            padding-right: 36px;
        }
        &.container-simple {
            padding-top: 0;
            padding-bottom: 0;
        }
        &.container-donate {
            padding-top: 0;
            padding-bottom: 0;
        }
        &.container-video .text-wrap .shadow {
            margin-top: 0;
        }
        &.container-simple .text-wrap {
            padding-top: 36px;
            padding-bottom: 36px;
            padding-left: 51px;
            padding-right: 51px;
        }
        .img-wrap {
            padding-top: 36px;
            padding-left: 36px;
            padding-right: 36px;      
        }
    }
    .text-wrap {
        padding-left: 36px;
        padding-right: 36px;
    }    
    .container-simple {
        .cat-roaming-full {
            display: block;
        }
    }
    .btn {
        min-height: 60px;
        min-width: 160px;
    }
    h1 {
        font-size: 4em;
    }
    .slider {
        .slider-info {
            h1 {
                max-width: 17ch;
                padding: 0 36px 36px 36px;
                margin-top: 0;
            }
            .btn-wrap {
                padding-left: 36px;
            }
        }
        .bx-wrapper .bx-viewport .bxslider li {
            background-position: center right 34%;
        }
    }
}
@media (max-width:690px){
    .footer-links {
        i.fa-envelope,
        i.fa-facebook-square {
            font-size: 1.5em;
        }
        span {
            display: none;
        }
    }
}
@media (max-width:480px){
    .btn {
        width: 100%;
        margin-bottom: 1em;
    }
    header .logo {
        width: 100%;
        height: auto;
    }
    .slider {
        .slider-info {
            h1 {
                width: 100%;
                text-align: center;
            }
            .btn-wrap {
                padding-right: 36px;
            }        
        }
        .bx-wrapper .bx-viewport .bxslider li:after {
            background-color:  rgba(0,60,82,0.3);
            width: 100%;
        }
    }
    .container-fluid {
        &.container-buy-now {
            .text-wrap.centered {
                text-align: center;
            }
        }
        &.container-donate .text-wrap {
            padding-top: 36px;
            padding-bottom: 36px;
        }        
    }
    footer {
        .no-padding:first-child ul li {
            display: block;
            padding: 1em 0;
        }
        .footer-links {
            i.fa-envelope,
            i.fa-facebook-square {
                font-size: 1em;
            }
            span {
                display: inline;
            }
        }        
    }
}